import React, {useState, useEffect} from 'react';
import { Route, Redirect } from "react-router-dom";
import axios from 'axios';

function PrivateRoute({component: Component, ...rest}) {

    if(localStorage.getItem('jwt') && !axios.defaults.headers.common['Authorization']) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('jwt')}`;
    }

    return (    
        <Route {...rest} render={props => (
            localStorage.getItem('jwt') ? 
            <Component {...props}></Component> :
            <Redirect to="/login"></Redirect>
        )}/> 
    )
}

export default PrivateRoute