import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Form, FormGroup, Label, Input, FormText, Button, Container, Row, Col} from 'reactstrap';
import Config from '../Config'

function Login({history}) {

    const [loginData, setLoginData] = useState({
        username: '',
        password: ''
    })
    const [error, setError] = useState('')

    function handleChange(name) {
        return function(event) {
            setLoginData({...loginData, [name]:event.target.value})
        }
    }

    async function handleSubmit(event) {
        try {
            event.preventDefault();
            const response = await axios.post(`${Config.backend}/web/login`, loginData);
            const {success, token} = response.data;
            if(success) {                
                localStorage.setItem('jwt', token);           
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                history.push('/clientes')
            } else {
                setError('Error iniciando sesión')
            }
        } catch(e) {
            console.error(e);
        }
    }

    return (
        <div>
            <h2>Cuentame</h2>
            <div className="group">            
                <Container>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label for="username">Usuario</Label>
                                    <Input type="text" id="username"
                                        autoComplete="username"
                                        onChange={handleChange('username')}>                                    
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="password">Contraseña</Label>
                                    <Input type="password" id="password"
                                        autoComplete="current-password"
                                        onChange={handleChange('password')}></Input>
                                </FormGroup>
                                <Button type="submit">Iniciar sesión</Button>
                            </Form>
                            {
                                error ? 
                                <p style={{color:'red'}}>{error}</p> : null
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Login;