import React, {useState, useEffect} from 'react';
import {Table} from 'reactstrap';
import {Form, FormGroup, Label, Input, FormText, Button, Container, Row, Col} from 'reactstrap';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import queryString from 'query-string';
import moment from 'moment';
import DateFormat from '../formatters/DateFormat';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import {  Link } from "react-router-dom";
import Config from '../Config'


function RemainingDocuments({client}) {
    switch(client.type) {
        case 'DEMO': return <span>Sin límite</span>
        case 'PERSONAL': return <span>Sin límite</span>
        case 'EMPRESARIAL': return <span>Sin límite</span>
        case 'CORPORATIVO': return <span>{client.remainingDocuments}</span>
    }
}

function RemainingUsers({client}) {
    switch(client.type) {
        case 'DEMO': return <span>0</span>
        case 'PERSONAL': return <span>0</span>
        case 'EMPRESARIAL': return <span>{client.remainingUsers}</span>
        case 'CORPORATIVO': return <span>Sin límite</span>
    }
}

function ValidUntil({client}) {
    switch(client.type) {
        case 'DEMO': return <span><DateFormat>{client.validUntil}</DateFormat></span>
        case 'PERSONAL': return <span>Sin límite</span>
        case 'EMPRESARIAL': return <span>Sin límite</span>
        case 'CORPORATIVO': return <span>Sin límite</span>
    }
}

function Clients({match, history, location}) {

    const [page, setPage] = useState(1)
    const [clients, setClients] = useState([]);
    const [clientsCount, setClientsCount] = useState(0);
    const [pagesCount, setPagesCount] = useState(0);    
    const [filter, setFilter] = useState({
        name: '',
        authority: '',
        from: moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate()
    })
    

    useEffect(() => {

        const query = queryString.parse(location.search);
        const page = query.page || 1;
        const data = ({
            name: query.n || '',
            authority: query.a || '',
            from: query.from || moment().startOf('month').format('YYYY-MM-DD'),
            to: query.to || moment().endOf('month').format('YYYY-MM-DD')
        })
        async function getClients() {
            console.log('getting clients');
            try {                
                const response = await axios.get(`${Config.backend}/web/clients?page=${page}&n=${data.name}&a=${data.authority}&from=${moment(data.from).format('YYYY-MM-DD')}&to=${moment(data.to).format('YYYY-MM-DD')}`)                
                setClients(response.data);                
            } catch(e) {
                console.error(e)
            }
        }

        async function countClients() {
            console.log('getting clients count');
            try {                
                const response = await axios.get(`${Config.backend}/web/clients/count?&n=${data.name}&a=${data.authority}&from=${moment(data.from).format('YYYY-MM-DD')}&to=${moment(data.to).format('YYYY-MM-DD')}`)                
                setClientsCount(response.data.count);
                setPagesCount(Math.ceil(response.data.count / 10));                
            } catch(e) {
                console.error(e)
            }
        }

        setPage(page);
        setFilter({...data, from: moment(data.from).toDate(), to: moment(data.to).toDate()});
        countClients();
        getClients();
    }, [location.search])
    
    function handleClientClick(client) {
        history.push(`/cliente/${client.id}`)
    }

    function handleChange(name) {
        return function(event) {
            setFilter({...filter, [name]:event.target.value})
        }
    }

    function handleDateChange(name) {
        return function(value) {
            setFilter({...filter, [name]:value})
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        history.push(`/clientes?page=${page}&n=${filter.name}&a=${filter.authority}&from=${moment(filter.from).format('YYYY-MM-DD')}&to=${moment(filter.to).format('YYYY-MM-DD')}`)
    }

    return (
        <div>
            <h2>Clientes</h2>
            <div className="group">
                <Container>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col>
                                        <FormGroup>                
                                            <Label for="name">Nombre</Label>
                                            <Input type="text" name="name" value={filter.name} onChange={handleChange('name')}/>
                                        </FormGroup>
                                        <FormGroup>                
                                            <Label for="authority">Autoridad</Label>
                                            <Input type="text" name="authority" value={filter.authority} onChange={handleChange('authority')}/>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="from">Desde </Label><br/>
                                            <DatePicker id="from" selected={filter.from} onChange={handleDateChange('from')}></DatePicker>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="to">Hasta </Label><br/>
                                            <DatePicker id="to" selected={filter.to} onChange={handleDateChange('to')}></DatePicker>
                                        </FormGroup>                                        
                                    </Col>
                                </Row>
                                <Button>Buscar</Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <br/>
                Resultados encontrados: {clientsCount}
                <br/>
                <div>
                    <Table className="clickableTable" striped={true}>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Autoridad</th>
                                <th>Tipo</th>
                                <th>Documentos</th>
                                <th>Usuarios</th>
                                <th>Válido hasta</th>
                                <th>Creado en</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clients.map(c => (
                                <tr key={c.id} onClick={() => handleClientClick(c)}>
                                    <td>{c.name}</td>
                                    <td>{c.authority}</td>
                                    <td>{c.type}</td>
                                    <td>
                                        <RemainingDocuments client={c}></RemainingDocuments>
                                    </td>
                                    <td>
                                        <RemainingUsers client={c}></RemainingUsers>
                                    </td>
                                    <td>
                                        <ValidUntil client={c}></ValidUntil>
                                    </td>
                                    <td>
                                        <DateFormat>{c.createdAt}</DateFormat>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination>
                        {
                            page > 1 ?
                            <PaginationItem>
                                <PaginationLink tag={Link} first to={`/clientes/1`}></PaginationLink>
                            </PaginationItem> : null
                        }
                        {
                            page > 1 ?
                            <PaginationItem>
                                <PaginationLink tag={Link} first to={`/clientes/${page - 1}`}></PaginationLink>
                            </PaginationItem> : null
                        }
                        {
                            [...Array(pagesCount)].map((n, i) => (
                                <PaginationItem key={i}>
                                    <PaginationLink tag={Link} to={`/clientes/${i + 1}`}>{i + 1}</PaginationLink>
                                </PaginationItem>
                            ))
                        }
                        {
                            page < pagesCount ?
                            <PaginationItem>
                                <PaginationLink tag={Link} next to={`/clientes/1`}></PaginationLink>
                            </PaginationItem> : null
                        }
                        {
                            page < pagesCount ?
                            <PaginationItem>
                                <PaginationLink tag={Link} last to={`/clientes/${page - 1}`}></PaginationLink>
                            </PaginationItem> : null
                        }
                    </Pagination>
                </div>
            </div>
        </div>
    )
}

export default Clients;
