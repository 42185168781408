import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {Button} from 'reactstrap';
import Clients from './clients/Clients';
import Client from './clients/Client';
import Login from './admins/Login'
import PrivateRoute from './admins/PrivateRoute'

function App() {

  function handleLogoutClick() {
    localStorage.removeItem('jwt');
    window.location.reload();
  }

  return (
    <Router>
      <div className="App">  
        <Route path="/" component={() => 
          {
            return localStorage.getItem('jwt') ? 
            <div className="topBar">
              <Button onClick={handleLogoutClick}>Cerrar sesión</Button>
            </div> :
            null
          }} 
        ></Route>       
        <Route exact path="/login" component={Login}/>
        <PrivateRoute path="/clientes" component={Clients}/>
        <PrivateRoute path="/cliente/:id" component={Client}/>
      </div>
    </Router>
  );
}

export default App;
