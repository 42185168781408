import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Form, FormGroup, Label, Input, FormText, Button, Container, Row, Col} from 'reactstrap';
import {Table} from 'reactstrap';
import moment from 'moment';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import ClientForm from './forms/ClientForm';
import Config from '../Config'


function Client({match}) {

    const clientId = match.params.id;
    
    const [filter, setFilter] = useState({
        from: moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate()
    })        
    const [shortReport, setShortReport] = useState({})

    const [eventsFilter, setEventsFilter] = useState({
        from: moment().startOf('month').toDate(),
        to: moment().endOf('month').toDate(),
        eventType: 'DOCUMENT_UPLOADED'
    })
    const [eventsReport, setEventsReport] = useState([])

    function handleDateChange(name) {
        return function(value) {
            setFilter({...filter, [name]:value})
        }
    }

    function handleEventsDateChange(name) {
        return function(value) {
            setEventsFilter({...eventsFilter, [name]:value})
        }
    }

    function handleChange(name) {
        return function(event) {
            setEventsFilter({...eventsFilter, [name]:event.target.value})
        }
    }

    async function handleSearch(event) {
        event.preventDefault();
        const from = moment(filter.from).format('YYYY-MM-DD');
        const to = moment(filter.to).format('YYYY-MM-DD');
        const response = await axios.get(`${Config.backend}/web/client/${clientId}/report?from=${from}&to=${to}`);
        setShortReport(response.data);
    }

    async function handleEventsSearch(event) {
        event.preventDefault();
        const from = moment(eventsFilter.from).format('YYYY-MM-DD');
        const to = moment(eventsFilter.to).format('YYYY-MM-DD');
        const response = await axios.get(`${Config.backend}/web/client/${clientId}/report/users?from=${from}&to=${to}&eventType=${eventsFilter.eventType}`);
        setEventsReport(response.data)
    }


    return (
        <div>
            <h2>Cliente</h2>
            <ClientForm clientId={clientId}/>
            <br/>
            <h3>Totales</h3>
            <div className="group">                
                <Container>
                    <Row>
                        <Col>
                            <Form onSubmit={handleSearch}>
                                <FormGroup>
                                    <Label for="from">Desde: </Label>
                                    <DatePicker id="from" selected={filter.from} onChange={handleDateChange('from')}></DatePicker>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="to">Hasta: </Label>
                                    <DatePicker id="to" selected={filter.to} onChange={handleDateChange('to')}></DatePicker>
                                </FormGroup>
                                <Button>Buscar</Button>
                            </Form>
                        </Col>
                        <Col>
                            Documentos: {shortReport.documents}
                        </Col>
                    </Row>
                </Container>                            
            </div>
            <br/>
            <h3>Totales por usuario</h3>
            <div className="group">
                <Container>
                    <Row>
                        <Col>
                            <Form onSubmit={handleEventsSearch}>
                                <FormGroup>
                                    <Label for="eventType">Evento</Label>
                                    <Input type="select" name="eventType" id="eventType" 
                                      value={eventsFilter.eventType}
                                      onChange={handleChange('eventType')}>
                                      <option value="DOCUMENT_UPLOADED">Documento subido</option>
                                      <option value="DOCUMENT_UPLOAD_DENIED">Documento subido negado</option>
                                      <option value="USER_REQUESTED">Usuario solicitado</option>
                                      <option value="USER_RELEASED">Usuario liberado</option>
                                      <option value="USE_USER_REQUEST_DENIED">Usuario negado</option>
                                      <option value="SIGNED_UP">Registrado</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="from">Desde: </Label>
                                    <DatePicker id="from" selected={eventsFilter.from} onChange={handleEventsDateChange('from')}></DatePicker>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="to">Hasta: </Label>
                                    <DatePicker id="to" selected={eventsFilter.to} onChange={handleEventsDateChange('to')}></DatePicker>
                                </FormGroup>
                                <Button>Buscar</Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>                
                <br/>
                <Table striped={true}>
                    <thead>
                        <tr>
                            <th>Usuario</th>
                            <th>Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {eventsReport.map((e, i) => (
                            <tr key={i}>
                                <td>{e.commonName}</td>
                                <td>{e.count}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default Client;