import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Form, FormGroup, Label, Input, FormText, Container, Row, Col, Button} from 'reactstrap';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
import Config from '../../Config'

function Field({name, label, client, onChange}) {
    return (
        <FormGroup>
            <Label for={name}>{label}</Label>
            <Input type="text" id={name} value={client[name]} onChange={onChange(name)}/>
        </FormGroup>
    )
}

function ClientForm({clientId}) {
    
    const [client, setClient] = useState({
        name: '',
        authority: '',
        firmamexId: '',
        type: '',
        remainingDocuments: 0,
        remainingUsers: 0,  
        email: '',
        dirty: false,
        validUntil: new Date() 
    });

    useEffect(() => {
        async function getClient() {
            try {
                const response = await axios.get(`${Config.backend}/web/client/${clientId}`);
                response.data.email = response.data.email || '';
                setClient(response.data);
            } catch(e) {
                console.error(e);
            }
        }
        getClient();
    }, [clientId])

    function handleChange(name) {
        return function(event) {
            const newClient = {...client, [name]:event.target.value, dirty:true};
            setClient(newClient)
        }
    }

    function handleDateCange(name) {
        return function(value) {
            setClient({...client, [name]: value, dirty: true})
        }
    }

    async function handleClick() {
        try {
            const response = await axios.post(`${Config.backend}/web/client`, client);
            setClient({...client, dirty: false});            
        } catch(e) {
            if(e.response) {
                alert(e.response.data)
            } else {
                alert(e)
            }            
        }        
    }

    function limitDocuments(clientType) {
        return clientType === 'CORPORATIVO';
    }

    function limitUsers(clientType) {
        return clientType === 'EMPRESARIAL';
    }    

    return (
        <div className="group">
            <Container>
                <Row>
                    <Col>
                        <Form >
                            <FormGroup>                
                            <Label for="clientType">Tipo</Label>
                            <Input type="select" name="clientType" id="clientType" 
                                value={client.type}
                                onChange={handleChange('type')}>
                                <option>DEMO</option>
                                <option>PERSONAL</option>
                                <option>EMPRESARIAL</option>
                                <option>CORPORATIVO</option>
                            </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="nombre">Nombre</Label>
                                <Input type="text" name="nombre" id="nombre" value={client.name} readOnly/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="authority">Autoridad</Label>
                                <Input type="text" name="authority" value={client.authority} readOnly/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="firmamexId">Firmamex Id</Label>
                                <Input type="text" name="firmamexId" value={client.firmamexId} readOnly/>
                            </FormGroup>
                            {
                                limitDocuments(client.type) ?
                                <Field client={client} name="remainingDocuments" label="Documentos restantes" onChange={handleChange}></Field>                 
                                : null
                            }
                            {
                                limitUsers(client.type) ?
                                <Field client={client} name="remainingUsers" label="Usuarios restantes" onChange={handleChange}></Field>                 
                                : null
                            }
                            {
                                client.type !== 'DEMO' ?
                                <Field client={client} name="email" label="Correo" onChange={handleChange}></Field>                 
                                :null
                            }  
                            {
                                client.type === 'DEMO' ?
                                <FormGroup>
                                    <Label for="validUntil">Válido hasta </Label><br/>
                                    <DatePicker id="validUntil" selected={client.validUntil} onChange={handleDateCange('validUntil')}></DatePicker>
                                </FormGroup>
                                :null
                            }  
                            <br/>                         
                        </Form>
                        {
                            client.dirty ?
                            <Button onClick={handleClick}>Guardar</Button> : null
                        }                        
                    </Col>
                </Row>
            </Container>
        </div>
    )

}

export default ClientForm;